import React from 'react';
import { Box, Heading, Text, OrderedList, ListItem, Button, Link} from '@chakra-ui/react';
import ApplicationFAQ from './ApplicationFAQ';
import './fonts/fonts.css';

const ApplicationInfo = () => {
    return (
        <Box
        bg="#FFFFEA"
        borderRadius={{ base: "10px", lg: "20px" }}
        width={{ base: "90%", lg: "94%", "2xl": "90%" }}
        height={{ base: "40%", lg: "80%", "2xl": "90%" }}
        overflow="hidden"
        position="relative"
        py="3%" // Consolidated padding-top and padding-bottom
        >
            <Box p={"5%"}>
                <Heading
                fontSize={{ base: "4vw", lg: "4vw", "2xl": "4vw" }}
                color="black"
                opacity="90%"
                fontFamily="Nohemi"
                align={"center"}
                >
                    2025 CYLC Application Process
                </Heading>
                <Text
                align={"center"}
                fontSize={{ base: "2.3vw", lg: "1.5vw", "2xl": "1vw" }}
                pb={"3%"}
                fontFamily={"NohemiLight"}
                >
                    Welcome to 2025 CYLC; please be aware that the application has a two-step process. You may always 
                    refer to the Application Q&A for additional information.
                </Text>
                <Heading
                fontSize={{ base: "2.3vw", lg: "2vw", "2xl": "2vw" }}
                fontFamily={"Nohemi"}
                >
                    Step One: Application
                </Heading>
                <OrderedList as="ol" fontSize={{base: "2vw", lg:"1.5vw", "2xl": "1vw"}} p={"2%"} fontFamily={"NohemiLight"}>
                    <ListItem>
                        Fill out the requested camper general information online by following the application instructions.
                    </ListItem>
                    <ListItem>
                        Please ensure that it is the PARENT email that is inputted.
                    </ListItem>
                    <ListItem>
                        After you submit the Step 1 Google Form, a copy of your responses will be sent to your email. 
                        Please save this for your own records.
                    </ListItem>
                    <ListItem>
                        CYLC will send a notification through email to confirm whether or not the applicant’s 
                        submitted application was accepted by CYLC, depending on the availability of camper spots 
                        for the applicant’s grade level.
                    </ListItem>
                    <ListItem>
                        Applicants will be placed on the waiting list if the applicant’s grade level is full.
                    </ListItem>
                    <ListItem>
                        The acceptance email will be sent in 7 days after the completion of the online application.
                    </ListItem>
                    <ListItem>
                        The online application will continue to be available until the deadline.
                    </ListItem>
                </OrderedList>
                <Box display="flex" justifyContent={"center"} p={"4%"}>
                    <Button w={"60%"} h={"5vw"} bg={"#FFED68"} borderRadius={"20px"} fontSize={{base: "2vw", lg:"4w", "2xl": "2vw"}} p={"1%"} fontFamily={"NohemiLight"}>
                        Link to Application
                    </Button>
                </Box>
                <Heading
                fontSize={{ base: "2.3vw", lg: "2vw", "2xl": "2vw" }}
                fontFamily={"Nohemi"}
                >
                    Step Two: Acceptance Package
                </Heading>
                <OrderedList as="ol" fontSize={{base: "2vw", lg:"1.5vw", "2xl": "1vw"}} p={"2%"} fontFamily={"NohemiLight"}>
                    <ListItem>
                        Once you receive the acceptance email from CYLC (not just a confirmation of receipt of your application), 
                        please complete the Step 2-Acceptance Package (Google Form) found in the acceptance email.
                    </ListItem>
                    <ListItem>
                        Fill out the request information accurately. Print/complete/scan the waiver forms back into the Google Form.
                    </ListItem>
                    <ListItem>
                        A complete Acceptance Package should include:
                        <OrderedList as="ol" fontSize={{base: "2vw", lg:"1.5vw", "2xl": "1vw"}} p={"2%"} styleType={"lower-alpha"}>
                            <ListItem>
                                Application Form
                            </ListItem>
                            <ListItem>
                                Health Insurance Copy uploaded into the form
                            </ListItem>
                            <ListItem>
                                A&M Commerce Agreement for Waiver, Indemnification, Assumption of Risk and Medical Treatment 
                                Authorization Scan
                            </ListItem>
                            <ListItem>
                                A&M Commerce MRC Recreation Form Waiver
                            </ListItem>
                            <ListItem>
                                A&M Commerce Medical Release Form
                            </ListItem>
                            <ListItem>
                                CYLC Medical Release Form/Activity Permission Slip Waiver Scan
                            </ListItem>
                            <ListItem>
                                Camp fee check $710 (check payable to CYLC and mailed via physical mail)
                            </ListItem>
                        </OrderedList>
                    </ListItem>
                    <ListItem>
                        Mail the check to “CYLC, P.O. Box 868156, Plano, TX 75086.”
                    </ListItem>
                    <ListItem>
                        The complete Acceptance Package must be postmarked by February 9, 2024.  No late or incomplete 
                        Acceptance Packages will be accepted.  If you are on the waiting list, you will be notified of 
                        the status of your application sometime after February 9, 2024.
                    </ListItem>
                </OrderedList>
                <Text
                fontSize={{ base: "2.3vw", lg: "1.5vw", "2xl": "1.3vw" }}
                style={{color: "#FF716E"}}
                fontFamily={"Nohemi"}
                >
                    Note: DO NOT Mail in an Acceptance Package without receiving the acceptance email notice from CYLC.  
                    Failure to follow this instruction may result in disqualification of the application altogether. 
                    The Application Q&A below is available for additional information.
                </Text>
                <ApplicationFAQ />
                <Text
                fontSize={{ base: "2.3vw", lg: "1.5vw", "2xl": "1.3vw" }}
                style={{color: "#FF716E"}}
                fontFamily={"Nohemi"}
                >
                    Note: Selected Counselors and Assistant Counselors may only be excused from a maximum of two trainings, however the training sessions on July 13-14 and July 21 are mandatory. With the exception of illness and emergencies, all excused absences must be disclosed in advance and preapproved by the CYLC committee. C/ACs with any unexcused absences or who miss a training not due to special circumstances (e.g., illness or emergencies)  will be removed from the C/AC team. Tardiness or early departure is considered an absence.
                </Text>
                <Text
                fontSize={{ base: "2.3vw", lg: "1.5vw", "2xl": "1.3vw" }}
                style={{color: "#FF716E"}}
                mt={"2%"}
                fontFamily={"Nohemi"}
                >
                    The dates and event place may be changed subject to availability - emails will be sent for notice.  The C/AC team may need to fulfill the team work or assignment responsibilities during weekdays. It is very important for C/ACs to commit to the training schedule. 
                </Text>
                <Heading
                fontSize={{base: "2.3vw", lg: "2vw", "2xl": "2vw" }}
                mt={"2%"}
                fontFamily={"Nohemi"}
                >
                    CYLC Parents’ conference is a Zoom meeting on Sunday, 1/14 from 2:30-3:30pm CT. We welcome parents and students to join us to better learn about CYLC and the application process. 
                </Heading>
                <Text
                fontSize={{ base: "2.3vw", lg: "1.8vw", "2xl": "1.4vw" }}
                p={"2%"}
                color={"#00cecb"}
                fontFamily={"NohemiLight"}
                >
                    Link: <Link href="https://us06web.zoom.us/j/84169267612?pwd=9LwPa7nS9cQk6aDQllluFqswgdmGwn.1" isExternal>https://us06web.zoom.us/j/84169267612?pwd=9LwPa7nS9cQk6aDQllluFqswgdmGwn.1 </Link> <br/>
                    Meeting ID: 841 6926 7612 <br/>
                    Passcode: 722727
                </Text>
                <Heading
                fontSize={{ base: "2.3vw", lg: "2vw", "2xl": "2vw" }}
                mt={"2%"}
                fontFamily={"Nohemi"}
                >
                    *For any other questions, please email cylcdfw@gmail.com
                </Heading>
            </Box>
        </Box>
    );
};

export default ApplicationInfo;