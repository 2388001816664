import React, { useState, useRef, useEffect } from "react";
import { Box, Text, VStack, HStack, Image, IconButton, Heading } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import "./fonts/fonts.css";

const GalleryBlock = React.memo(({ title, categories, images, subtext }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageRef = useRef(null);
  const [imageWidth, setImageWidth] = useState(0);
  const autoScrollInterval = useRef(null);

  useEffect(() => {
    if (imageRef.current) {
      const { width, marginRight } = window.getComputedStyle(imageRef.current);
      setImageWidth(parseFloat(width) + parseFloat(marginRight));
    }
  }, []);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (!prevIndex ? images.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  // Auto-scroll logic
  useEffect(() => {
    autoScrollInterval.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(autoScrollInterval.current); // Cleanup interval on unmount
  }, [images.length]);

  return (
    <Box
      bg="#00CECB"
      borderRadius={{ base: "10px", lg: "20px" }}
      width="97%"
      height="100%"
      overflow="hidden"
      position="relative"
      pt={"3%"}
      pb={"3%"}
      pl={"3%"}
    >
      <HStack justify="space-between" spacing={0}>
        <VStack
          align="start"
          maxW="45%"
          ml="3%"
        >
          {subtext.map((text) => (
            <Text
              key={text}
              fontFamily="NohemiLight"
              fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1.25vw" }}
            >
              {text}
            </Text>
          ))}
          {title.map((titleText) => (
            <Heading
              key={titleText}
              fontSize={{ base: "4vw", lg: "4.5vw", "2xl": "3.5vw" }}
              fontFamily="MeshedDisplay"
            >
              {titleText}
            </Heading>
          ))}
        </VStack>
      </HStack>

      <Box mt="5%" position="relative">
        <HStack overflowX="hidden" ml="20%" pb="2%" position="relative">
          <Box
            display="flex"
            width="100%"
            transition="transform 0.5s ease"
            transform={`translateX(-${currentIndex * imageWidth}px)`}
            css={{
              scrollbarWidth: "none", // Firefox
              "&::-webkit-scrollbar": { display: "none" }, // Chrome, Safari, Edge
            }}
          >
            {images.map((image, index) => (
              <Image
                key={index}
                src={image}
                alt={`Image ${index + 1}`}
                borderRadius={{ base: "10px", lg: "20px" }}
                boxSize={{ base: "90%", lg: "75%", "2xl": "55%" }}
                objectFit="cover"
                flexShrink={0}
                mr="5%"
                ref={index === 0 ? imageRef : null}
              />
            ))}
          </Box>
        </HStack>

        <HStack pt="3%" ml={"20%"}>
          <IconButton
            aria-label="Previous"
            icon={<ChevronLeftIcon />}
            onClick={handlePrevClick}
            size="lg"
            isRound
            bg="#FAE862"
          />
          <IconButton
            aria-label="Next"
            icon={<ChevronRightIcon />}
            onClick={handleNextClick}
            size="lg"
            isRound
            bg="#FAE862"
          />
        </HStack>
      </Box>
    </Box>
  );
});

export default GalleryBlock;
