import React from 'react';
import { 
    Box, 
    Heading, 
    Accordion, 
    AccordionItem, 
    AccordionButton, 
    AccordionPanel, 
    AccordionIcon, 
    OrderedList, 
    ListItem 
} from '@chakra-ui/react';

const faqData = [
    {
        question: "What does CYLC stand for?",
        answer: "CYLC stands for “Community Youth Leadership Camp”. The camp is held once a year and is usually on the 3rd or 4th week of July."
    },
    {
        question: "What's the age limit for the camp?",
        answer: "Currently, CYLC accepts 4th grade (age 10) to 12th grade students. Different grades may have more than one team based on age and number of applicants."
    },
    {
        question: "Does my child have to find his/her own roommate or will you pair them up?",
        answer: (
            <>
                CYLC will assign the roommate based on the following:
                <OrderedList>
                    <ListItem>Whether both campers list each other.</ListItem>
                    <ListItem>Whether one camper lists another.</ListItem>
                    <ListItem>If no roommate request is submitted, then CYLC will assign the roommate for that camper.</ListItem>
                </OrderedList>
            </>
        )
    },
    {
        question: "What's the dorm arrangement for the camp?",
        answer: "Each dorm room has two beds, a shared living space, and a private bath."
    },
    {
        question: "If I want to withdraw from the camp due to some emergency reason after I registered and paid in full, can I get full refund or partial refund and how much I can get? Is there a refund if my kid can't attend?",
        answer: "There is a $50 nonrefundable application fee. You will get the refund with a $50 registration fee deduction before March 31, 2024. No refund after April 1, 2024. Please submit a written request to withdraw from camp. Once you withdraw from camp, you will lose your spot in the camp and can’t request to get the spot back. Applicants cannot request a personal replacement if the applicant cannot attend the camp. No camp fees can be rolled over for next year."
    },
    {
        question: "May I pay the camp fee in installments?",
        answer: "No, the camp fee must be paid in full by check with the Acceptance Package."
    },
    {
        question: "If my child has to leave before the end of camp, will I still have to pay the full amount?",
        answer: "Yes, the full amount of camp fee should be submitted with the Acceptance Package. The camp fee is not refundable after April 1, 2024 (see question 5 for more details). Please plan your schedule accordingly to allow your camper to attend for the whole duration."
    },
    {
        question: "If my child has an important event during the camp, can they still attend the camp and miss one day of camp? Once my child leaves camp for another obligation, can he/she return to camp?",
        answer: "Yes, the camper may still attend the camp and miss one day. Please send in written notice to the team teacher and counselor before camp starts. It is the parent’s responsibility to organize transportation to and from camp."
    },
    {
        question: "If my child needs to take medicine every day, do you have a nurse to help him/her about this?",
        answer: "Yes, we have a nurse on site to take care of all medical situations. All prescription medications must be given to team teachers/counselors on the first day of check-in. Team teachers are responsible for ensuring that the camper takes the medicine on time. The camp site also has a medical center for general medical needs. CYLC will notify parents immediately of any medical emergencies should the situation arise."
    },
    {
        question: "Is the camp overnight? Any requirements for a kid to attend the camp?",
        answer: "Yes, CYLC is an overnight camp on a college campus. It is open to all children who meet the age requirements. The camp requires a lot of physical activities and teamwork; any misconduct may lead to dismissal from the camp without refund. CYLC will send out a parent’s letter in July with details of things to bring, check-in/out process, and the drop-off location for buses."
    },
    {
        question: "How many teachers/TAs/Counselors are in the camp?",
        answer: "CYLC has a dedicated teacher and a counselor team of 5-6 C/ACs for each team. Teachers/TAs/Counselors total about 85 people at camp. CYLC also has around 10 extra parents and volunteers to help on the first and last days of camp."
    },
    {
        question: "What are the activities in the camp?",
        answer: "The main activities include Leadership classes/workshops, dance/performing arts, arts & crafts, cooking, filming, performing arts, etiquette, self-defense, rock-climbing, team building, and team project and leadership-themed programs."
    },
    {
        question: "How safe is the camp?",
        answer: "The teams are always escorted by teachers and counselors and are under their supervision for every class. The dorm is dedicated for CYLC use only, and the entrance cards are only held by CYLC staff and counselors. On-duty teachers also walk around the floors to make sure campers are sleeping after curfew. Each floor has teachers, TAs, and C/ACs to ensure that campers’ needs are promptly met and addressed."
    },
    {
        question: "I am not in the same Ban/team as my friends. How are the Bans/teams split?",
        answer: "In most cases, the same grade level should be in the same team, but some of the grade levels may have more applicants than others, which may split them into different teams. CYLC cannot differentiate who is friends with whom and is not capable of meeting each camper’s needs/requests. CYLC encourages campers to meet new friends and learn new things."
    },
    {
        question: "Do I still have to go to Formal if I don't bring formal clothes?",
        answer: "Everyone in the camp is required to follow the scheduled events. CYLC will ask the parents to deliver formal clothes to camp before the formal night on Tuesday, July 18, if the student did not bring formal clothes. It is important for parents to read the parent’s letter and make sure all items are prepared before camp."
    },
    {
        question: "What should I bring to the camp? What's the drop-off and pick-up procedure of the camp?",
        answer: "Please refer to the parent’s letter in July for all details of things to bring and drop-off/pick-up schedule. You may call the teachers/counselors with questions then."
    },
    {
        question: "When is the camp reunion?",
        answer: "The camp reunion usually is scheduled on the weekend 1-2 weeks after the camp. We will send out an email to each camper for the date of the reunion."
    }
];

const AboutFAQ = () => {
    return (
        <Box bg="FFFFEA" p={"5%"} maxWidth="90%" mx="auto">
            <Accordion allowToggle fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                <Heading fontSize={"3vw"} textAlign={"center"} mt={"4%"} mb={"4%"} fontFamily={"Nohemi"}>
                    Frequently Asked General Questions
                </Heading>
                {faqData.map((faq, index) => (
                    <AccordionItem key={index}>
                        <Heading>
                            <AccordionButton>
                                <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }} fontFamily={"NohemiLight"}>
                                    {faq.question}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </Heading>
                        <AccordionPanel pb={4} fontFamily={"NohemiLight"}>{faq.answer}</AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Box>
    );
};

export default AboutFAQ;
