import React from 'react';
import { Box } from "@chakra-ui/react";
import Title from './Title';
import AboutInfo from './AboutInfo';

function About(){
    const titleLineOne = ['This is'];
    const titleLineTwo = ['Our Purpose'];

    return(
        <Box>
            <Box
            bg={'#00cecb'}
            maxW={'100%'}
            height={'100%'}
            >
                <Title
                titleLineOne={titleLineOne}
                titleLineTwo={titleLineTwo}
                />
            </Box>
            <Box
            bg={'#00cecb'}
            justifyContent={"center"}
            display={"flex"}
            paddingBottom={"7%"}
            >
                <AboutInfo />
            </Box>
        </Box>
    )
}

export default About;