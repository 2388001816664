import React from 'react';
import { 
    Box, 
    Heading, 
    Accordion, 
    AccordionItem, 
    AccordionButton, 
    AccordionPanel, 
    AccordionIcon, 
    ListItem, 
    UnorderedList 
} from '@chakra-ui/react';
import './fonts/fonts.css';

const applicationQuestions = [
    {
        question: "Why does CYLC have a Two-step Application Process?",
        answer: "In the past years of 2018 & 2019, CYLC received more than 1300 applications during Step One online application and accepted the first 400 based on their timestamp, who were then notified to submit the Step Two Acceptance Package. To save all parties’ time and effort, CYLC has adopted this two-step process to timestamp all applications and ensure that all applicants have a fair opportunity to apply and attend."
    },
    {
        question: "How do I apply using this two-step process?",
        answer: "Please refer to the CYLC Two-Step Application Process Instructions."
    },
    {
        question: "Which website should I go to for the registration or would the link be distributed through email?",
        answer: "Please check on www.cylc-dfw.org and go to the Application tab to find the information. You may also refer to CYLC’s Facebook page to obtain the updated information. CYLC will send an email reminder for the application process, but it is your responsibility to check the website regularly for details or updates as changes may occur during the process."
    },
    {
        question: "Is the online application reviewed on a first-come-first-serve basis?",
        answer: "Yes, the online application is sorted using the timestamp on the final confirmation page and reviewed on a “first-come-first-serve” basis. The application for most grade levels was filled in less than 1 minute 40 seconds in past years. It is important to have all necessary information (Camper personal information, parents’ contact, shirt size, roommate, past camp years…) in hand to complete the application."
    },
    {
        question: "When will the applications be available online?",
        answer: "Starts 1/22 Monday, ends 1/28 Sunday."
    },
    {
        question: "May I send in the Acceptance Package directly without first completing Step 1?",
        answer: "No, Acceptance Packages/Step 2s directly submitted without first completing the Step 1 process and receiving an acceptance email with further instructions will NOT be accepted and will not constitute a valid application. Please wait until you receive the email acceptance notification and then submit the paper Acceptance Package."
    },
    {
        question: "How soon should I turn in my Acceptance Package?",
        answer: "Please wait until you receive the acceptance confirmation email from CYLC. Then complete the Acceptance Package by following the instructions of step two, described in the instructions section of the application process. Complete the Google Form Step 2 Acceptance Package and mail the camp fee check of $710 to CYLC, P.O. Box 868156, Plano, TX 75086, postmarked on or before February 9, 2024. Please double-check that the Acceptance Package is completed before mailing, as an incomplete Acceptance Package may be subject to disqualification."
    },
    {
        question: "Is the applicant 'IN' when I complete the online application?",
        answer: "No, completing the online application is only the first step. Once you receive an email notice that your application has been ACCEPTED, please submit the Step 2 Acceptance Package with the required documents as instructed and postmarked on or before February 9, 2024."
    },
    {
        question: "Do I need to do the online application if I am applying for a counselor?",
        answer: "Yes, both camper and counselor applicants need to apply online as the first step. In step two, you need to complete camper forms as instructed. You may apply online for counselor and assistant counselor after completing the camper application. We have limited spots; there is no privilege for past counselors in the application. Please follow the process carefully as instructed."
    },
    {
        question: "Do I get a confirmation after completing the online application?",
        answer: "You will get a copy of your Google form responses in your email at the end of the online application (for both Step 1 and Step 2). Please save that email copy of your responses for your record."
    },
    {
        question: "I messed up my application; can I make a change without affecting whether or not I get in?",
        answer: "It is your responsibility to follow the application process and carefully check all the documents before submission. Any mistakes may adversely affect the processing of your application, especially the correct contact information such as email address and cell numbers. The uniform size will also be based on what is recorded on the application."
    },
    {
        question: "How quickly do spots run out?",
        answer: "It depends on how many applicants apply for each age team. In 2018 and 2019, the teams were filled on average in 1 minute 20 seconds. The times ranged from 1 min to 1 min 45 sec."
    },
    {
        question: "How many campers will CYLC take? How many groups and what is the group size?",
        answer: "CYLC will take 400 applicants, including assistant counselors and counselors. There are anywhere between eight to ten teams, and each team has on average 25-35 campers (balanced by gender)."
    },
    {
        question: "Is there a place I can specify who I want to room with during camp?",
        answer: "Yes, please specify who you want to room with during camp. CYLC will assign a roommate for you if your roommate did not get in or you did not specify a roommate on the application form. You may specify who you do not want to room with as well, but there is no guarantee either way. We will do our best to accommodate all requests."
    },
    {
        question: "Will everyone get a confirmation email?",
        answer: "Yes, CYLC will email the acceptance notice and you may follow instructions in that email acceptance to complete the Step 2 Acceptance Package online Google Form and mail in the check. You will also receive an email notice if the team is full and you are put on the applicant waitlist."
    },
    {
        question: "Can I pay the camp fee online? For example by Paypal or credit card? What method of payment will be accepted for the camp?",
        answer: "No, the online application is the first step, and you don’t pay the camp fee until you submit your Step 2 Acceptance Package (if you are accepted). After you receive the acceptance notice, then you should include the camp fee through mail and submit a picture of the check in your Step 2. Personal checks and money orders are accepted for the camp. Any returned check penalty will be paid by the applicant. No credit card/PayPal will be accepted."
    },
    {
        question: "Shall I register by age group or by grade level?",
        answer: "Please follow the grade level and sign up on the assigned date. CYLC reserves the right to verify an applicant’s birthday or school grade level, as any “incorrect” birthday or grade level may cause disqualification. CYLC may request the birth certificate or school report card as a reference."
    },
    {
        question: "May I register the camper after the application week?",
        answer: "Yes, you may still sign the camper up after the application week. Online Application will remain open until February 9, 2024 or close early by announcement. Your information will still be time stamped after completing Step 1."
    }
];

const counselorQuestions = [
    {
        question: "Do I need to turn in the camper application in order to apply to be a counselor?",
        answer: "Yes, please submit the online application first and mark that you are also applying for counselor. Once you receive the acceptance email, then submit the Acceptance Package. The online counselor Google Form application link will be emailed at the same time as the acceptance email."
    },
    {
        question: "How long does my essay need to be?",
        answer: "The essay is 250-650 words. Longer is not better as long as you adequately address the prompts."
    },
    {
        question: "How long do my answers to the questions need to be?",
        answer: "Answers should be concise and clear. We are looking for creative ideas and practical solutions."
    },
    {
        question: "How can I submit my counselor application package?",
        answer: "We have set up a Google Form for you to submit your question answers, resume, essay, and code of conduct. We will email the link to you directly after you are accepted through Step 1."
    },
    {
        question: "Are grades/rank a major factor in selecting C/ACs? What factors are considered?",
        answer: "No, CYLC provides equal opportunities for all candidates. The school ranking/grades are not the major factors for selection. CYLC is looking for the leadership traits of creativity, confidence, integrity, positive attitude, and behavior. Most important is being a good team player and a good role model for campers."
    },
    {
        question: "If I don’t make C/AC will I be a camper?",
        answer: "Yes, you will keep your spot as a camper. The ratio of C/AC selection rate is historically about 50%, but this is a great opportunity for you to prepare yourself early for college applications and the interview process. Every step of the C/AC application process is important. CYLC hopes this experience can help you build up confidence and also polish other leadership skills."
    },
    {
        question: "I have never been to CYLC camp before; can I still apply to be a counselor?",
        answer: "Yes, you have the same opportunities as other candidates who have attended CYLC before."
    },
    {
        question: "If I have an unscheduled emergency and I miss more trainings than allowed, will I be disqualified?",
        answer: "CYLC counselor training is very important. Please arrange your schedule accordingly so as not to conflict with the counselor training schedule especially for new assistant counselors. Missing any session will make it hard for you and your team to evenly divide the workload and will be unfair to other counselors. As such, all training sessions are mandatory, but reasonable excused absences will be considered on a case-by-case basis. With the exception of illness and emergencies, all excused absences must be disclosed in advance and preapproved by the CYLC committee."
    },
    {
        question: "I have absolutely no talent for dancing. How will this affect my candidacy?",
        answer: "CYLC is not a dance camp but dance is an important way to present your teamwork skills. Your actual skill level will not affect your counselor selection, but it is important to keep a positive attitude as that will be considered more heavily during the review process."
    },
    {
        question: "How can I improve my application?",
        answer: "Be yourself. CYLC does not have any cookie-cutter requirements in its counselor candidates. Each candidate will be considered individually and evaluated holistically based on his or her individual strengths and weaknesses. Pay attention to details and make your application look professional (e.g., make sure your application, resume, essay and picture are nice and clean). Each interview is six minutes long. Let the teachers get to know you, and you may always seek more practice by asking your parents for tips or speaking with past counselors for additional advice prior to the interview."
    },
    {
        question: "What is the counselor training schedule for 2024?",
        answer: (
            <UnorderedList>
                <ListItem>The Counselor application due date is 2/20/2024. You will be notified of your interview time by 2/23/2024. If you cannot make your scheduled time, you must arrange yourself to switch with another candidate and notify CYLC Admin Team by 2/25/2024.</ListItem>
                <ListItem>Counselor interview: 3/2 Saturday and 3/3 Sunday.</ListItem>
                <ListItem>The first Meet and Greet is 3/23 Saturday.</ListItem>
                <ListItem>Counselor Training dates are 6/22-6/23, 6/29-6/30, 7/13-7/14.</ListItem>
                <ListItem>Move in date is 7/21 Sunday.</ListItem>
            </UnorderedList>
        )
    }
];


const ApplicationFAQ = () => {
    return (
        <Box bg="FFFFEA" p={"5%"} maxWidth="90%" mx="auto">
            <Heading fontSize="3vw" textAlign="center" mb={"4%"} fontFamily={"Nohemi"}>
                Frequently Asked Application Questions
            </Heading>
            <Accordion allowToggle fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }} fontFamily={"NohemiLight"}>
                {applicationQuestions.map((item, index) => (
                    <AccordionItem key={index}>
                        <AccordionButton>
                            <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                {item.question}
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>{item.answer}</AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>

            <Heading fontSize="3vw" textAlign="center" mb={"4%"} mt={"4%"} fontFamily={"Nohemi"}>
                Counselor/Assistant Counselor Questions
            </Heading>
            <Accordion allowToggle fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }} fontFamily={"NohemiLight"}>
                {counselorQuestions.map((item, index) => (
                    <AccordionItem key={index}>
                        <AccordionButton>
                            <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                {item.question}
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>{item.answer}</AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Box>
    );
};

export default ApplicationFAQ;
