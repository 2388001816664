import React from 'react';
import ApplicationInfo from './ApplicationInfo';
import { Box } from '@chakra-ui/react';
import Title from './Title';

const Application = () => {
  const titleLineOne = ['Application'];
  const titleLineTwo = ['Information'];
  return (
    <Box>
      <Box
      bg={'#00cecb'}
      maxW={'100%'}
      height={'100%'}
      >
        <Title
        titleLineOne={titleLineOne}
        titleLineTwo={titleLineTwo}
        />
      </Box>
      <Box
      bg={'#00cecb'}
      justifyContent={"center"}
      display={"flex"}
      paddingBottom={"7%"}
      >
        <ApplicationInfo />
      </Box>
    </Box>
  );
};

export default Application;
